<template>
	<main>
		<el-tabs v-model="tab_name">
			<el-tab-pane label="标签管理" name="tag"></el-tab-pane>
			<el-tab-pane label="行为积分" name="event"></el-tab-pane>
			<el-tab-pane label="售前联系人" name="before"></el-tab-pane>
			<el-tab-pane label="售后联系人" name="after"></el-tab-pane>
			<el-tab-pane label="额度预警" name="balance"></el-tab-pane>
			<el-tab-pane label="协议管理" name="rule"></el-tab-pane>
		</el-tabs>
		<div class="form-box">
			<el-form ref="form" :model="form" label-width="140px">
				<div class="form-tab" v-show="tab_name === 'tag'">
					<el-form-item label="经销商产品分类" prop="tag_agent">
						<form-tag-input v-model="form.tag_agent"/>
					</el-form-item>
					<el-form-item label="资料产品分类" prop="tag_agent">
						<form-tag-input v-model="form.tag_file"/>
					</el-form-item>
					<el-form-item label="视频产品分类" prop="tag_agent">
						<form-tag-input v-model="form.tag_video"/>
					</el-form-item>
				</div>
				<div class="form-tab" v-show="tab_name === 'event'">
					<el-form-item label="注册会员" prop="event_register">
						<el-input-number v-model="form.event_register"/>
					</el-form-item>
					<el-form-item label="查看自建图文" prop="event_article">
						<el-input-number v-model="form.event_article"/>
					</el-form-item>
					<el-form-item label="查看公众号文章" prop="event_wechat">
						<el-input-number v-model="form.event_wechat"/>
					</el-form-item>
					<el-form-item label="提交体验店预约表单" prop="event_store">
						<el-input-number v-model="form.event_store"/>
					</el-form-item>
					<el-form-item label="提交经销商联系表单" prop="event_agent">
						<el-input-number v-model="form.event_agent"/>
					</el-form-item>
					<el-form-item label="防伪查询" prop="event_fake">
						<el-input-number v-model="form.event_fake"/>
					</el-form-item>
					<el-form-item label="资料下载" prop="event_file">
						<el-input-number v-model="form.event_file"/>
					</el-form-item>
					<el-form-item label="观看视频" prop="event_video">
						<el-input-number v-model="form.event_video"/>
					</el-form-item>
					<el-form-item label="提交售前表单" prop="event_consult_before">
						<el-input-number v-model="form.event_consult_before"/>
					</el-form-item>
					<el-form-item label="提交售后表单" prop="event_consult_after">
						<el-input-number v-model="form.event_consult_after"/>
					</el-form-item>
				</div>
				<div class="form-tab" v-show="tab_name === 'before'">
					<el-form-item label="姓名" prop="before_username">
						<el-input v-model="form.consult_before_username"/>
					</el-form-item>
					<el-form-item label="手机号码" prop="before_phone">
						<el-input v-model="form.consult_before_phone"/>
					</el-form-item>
					<el-form-item label="邮箱" prop="before_email">
						<el-input v-model="form.consult_before_email"/>
					</el-form-item>
				</div>
				<div class="form-tab" v-show="tab_name === 'after'">
					<el-form-item label="姓名" prop="after_username">
						<el-input v-model="form.consult_after_username"/>
					</el-form-item>
					<el-form-item label="手机号码" prop="after_phone">
						<el-input v-model="form.consult_after_phone"/>
					</el-form-item>
					<el-form-item label="邮箱" prop="after_email">
						<el-input v-model="form.consult_after_email"/>
					</el-form-item>
				</div>
				<div class="form-tab" v-show="tab_name === 'balance'">
					<el-form-item label="短信预警额度" prop="balance_sms_min">
						<el-input v-model="form.balance_sms_min"/>
					</el-form-item>
					<el-form-item label="邮件预警额度" prop="balance_email_min">
						<el-input v-model="form.balance_email_min"/>
					</el-form-item>
					<el-form-item label="预警通知手机号码" prop="balance_phone">
						<el-input v-model="form.balance_phone"/>
					</el-form-item>
					<el-form-item label="预警通知邮箱" prop="balance_email">
						<el-input v-model="form.balance_email"/>
					</el-form-item>
				</div>
				<div class="form-tab" v-show="tab_name === 'rule'">
					<el-form-item label="诺音曼隐私政策" prop="register_rule">
						<form-editor v-model="form.register_rule"/>
					</el-form-item>
					<el-form-item label="售后服务条款" prop="consult_after_rule">
						<form-editor v-model="form.consult_after_rule"/>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<el-button type="primary" @click="submit">保存</el-button>
	</main>
</template>
<script>
import {config} from "@/api/admin";

export default {
	data(){
		return {
			tab_name:"tag",
			form:{}
		}
	},
	mounted() {
		this.get();
	},
	methods:{
		get(){
			config.get().then((res)=>{
				this.form = res.detail;
			})
		},
		submit(){
			config.create(this.form).then((res)=>{
				this.$message.success('保存成功')
			})
		}
	}
}
</script>
